<template>
  <div class="profile-page">
    <div class="profile-detail">
      <div class="photo-profile">
        <div class="main-photo" @click="openModal">
          <img
            :src="applyImgSrc(currentUser.photo)"
            :alt="currentUser.name ? currentUser.name : ''"
          />
        </div>
        <div class="photo-gallery" v-if="false">
          <div
            class="photo-mini"
            v-for="(photo, index) in currentUserPhotos"
            :key="index"
          >
            <img
              :src="applyImgSrc(`${photo}`)"
              :alt="currentUser.name ? currentUser.name : ''"
            />
            <div
              class="overlay-all"
              v-if="index === currentUserPhotos.length - 1"
              @click="openModal"
            >
              See all photos
            </div>
          </div>
        </div>
      </div>
      <div class="profile-content">
        <div class="profile-page-info-case">
          <div class="name">

            <span class="orange-text">
            {{ currentUser.name }}
            </span>

            <div class="online-status">Online</div>
          </div>
          <div class="spacer"></div>
          <div class="camera">
            <div class="ico"></div>
            <span>Her web cam is turned on</span>
          </div>
        </div>
        <div class="profile-info">
          <div class="profile-info-column">
            <div class="profile-info-cell">
              <div class="label">Age:</div>
              <div class="value">{{ currentUser.age }}</div>
            </div>
            <div class="profile-info-cell">
              <div class="label">Country:</div>
              <div class="value">{{ currentUser.country }}</div>
            </div>
            <div class="profile-info-cell">
              <div class="label">Eyes color:</div>
              <div class="value">{{ currentUser.eyes_color }}</div>
            </div>
            <div class="profile-info-cell">
              <div class="label">Height:</div>
              <div class="value">{{ currentUser.height }}</div>
            </div>
            <div class="profile-info-cell">
              <div class="label">Marital status:</div>
              <div class="value">{{ currentUser.marital_status }}</div>
            </div>
            <div class="profile-info-cell">
              <div class="label">Physique:</div>
              <div class="value">{{ currentUser.physique }}</div>
            </div>
            <div class="profile-info-cell">
              <div class="label">Drinker:</div>
              <div class="value">{{ currentUser.drinker }}</div>
            </div>
          </div>
          <div class="profile-info-column">
            <div class="profile-info-cell">
              <div class="label">Zodiac:</div>
              <div class="value">{{ currentUser.zodiac }}</div>
            </div>
            <div class="profile-info-cell">
              <div class="label">City:</div>
              <div class="value">{{ currentUser.city }}</div>
            </div>
            <div class="profile-info-cell">
              <div class="label">Hair color:</div>
              <div class="value">{{ currentUser.hair_color }}</div>
            </div>
            <div class="profile-info-cell">
              <div class="label">Weight:</div>
              <div class="value">{{ currentUser.weight }}</div>
            </div>
            <div class="profile-info-cell">
              <div class="label">Children:</div>
              <div class="value">{{ currentUser.children }}</div>
            </div>
            <div class="profile-info-cell">
              <div class="label">Smoker:</div>
              <div class="value">{{ currentUser.smoker }}</div>
            </div>
            <div class="profile-info-cell">
              <div class="label">English:</div>
              <div class="value">{{ currentUser.english }}</div>
            </div>
          </div>
        </div>
        <div class="profile-action">
          <AppButton class="btn btn-orange" @click="openModal">
            <i class="vc vc-eye"></i>Give a wink
          </AppButton>
          <AppButton class="btn btn-orange" @click="openModal">
            <i class="vc vc-heart"></i>Like
          </AppButton>
          <AppButton class="btn-grey" @click="openModal">
            <i class="vc vc-star"></i>Add to favorites
          </AppButton>
          <AppButton class="btn-grey" @click="openModal">
            <i class="vc vc-gift"></i>Send a gift
          </AppButton>
          <AppButton class="btn-yellow" @click="openModal">
            <i class="vc vc-mail"></i>Send message
          </AppButton>
        </div>
        <div class="text-info" v-if="currentUser.about_en">
          <h2 class="blue-text">About me</h2>
          <p>
            {{ currentUser.about_en }}
          </p>
        </div>
        <div class="text-info" v-if="currentUser.desire_en">
          <h2 class="green-text">My desire</h2>
          <p>
            {{ currentUser.desire_en }}
          </p>
        </div>
      </div>
    </div>
    <h3 class="line">More single girls</h3>
    <div class="girls-list">
      <div class="col" v-for="user in recommendedUsers" :key="user.id">
        <AppProfileCard :user="user" />
      </div>
    </div>
  </div>
</template>

<script>
import AppProfileCard from "@/components/AppProfileCard";
import AppButton from "@/components/AppButton";
import openSignUpModalMixin from "@/mixins/openSignUpModalMixin";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
export default {
	name: "Profile",
	data() {
		return {
			currentUser: {
				name: "",
				photos: {},
			},
		};
	},
	components: { AppButton, AppProfileCard },
	mixins: [openSignUpModalMixin],
	computed: {
		...mapGetters({
			allUsers: "allUsers",
		}),
		recommendedUsers() {
			const allUserWithOutCurrent = this.allUsers.filter(
				(user) => +user.id !== +this.$route.params.id
			);
			return _.shuffle(allUserWithOutCurrent).slice(0, 12);
		},
		currentUserPhotos() {
			const photos = Object.values(this.currentUser.photos);

			for (let i = photos.length - 1; i > 0; i--) {
				let j = Math.floor(Math.random() * (i + 1));
				[photos[i], photos[j]] = [photos[j], photos[i]];
			}

			console.log(this.currentUser.photos + " this.currentUser.photos");

			console.log(photos + " photos");

			return photos.slice(0, 5);
		},
	},
	methods: {
		...mapActions({
			fetchUsers: "fetchUsers",
		}),
		applyImgSrc: function (_sUrl) {
			if (_sUrl !== undefined) {
				return "//loveinchat.com" + _sUrl;
			}
		},
	},
	async mounted() {
		await this.fetchUsers();
		const user = this.allUsers.find(
			(user) => +user.id === +this.$route.params.id
		);
		if (!user) {
			return this.$router.replace({ name: "NotFound" });
		}
		this.currentUser = user;
	},
};
</script>
